import React,{useEffect} from "react";
import "./cursor.css";

import {gsap, CSSRulePlugin} from "gsap/all"
gsap.registerPlugin(CSSRulePlugin)
function CustomCursor() {
     //follows the cursor
  const customRef = React.useRef(null)
  
  

  useEffect(() => {
    var tl = gsap;
    tl.set(".app-cursor", {xPercent:-50,yPercent:-50})
    tl.set(".follower", {xPercent:-50,yPercent:-50})

    window.addEventListener("mousemove", (e) =>{
      tl.to(".app-cursor",0,{x:e.clientX,y:e.clientY})
      tl.to(".follower",0.5,{x:e.clientX,y:e.clientY})
    })
    const hOne = document.querySelectorAll("h1")
    const hTwo = document.querySelectorAll("h2")
    const p = document.querySelectorAll("p")
    const cards = document.querySelectorAll(".parallax-card-shine")
    const panel = document.querySelectorAll(".image-container")
    const item = document.querySelectorAll(".item")

    cards.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{
        tl.to(".app-cursor",0.2,{width:"70px",height:"70px"})
        tl.to(".follower",0,{display:"none"})
        tl.to(".seeMore",0,{display:"block"})
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
        tl.to(".follower",0,{display:"block"})
        tl.to(".seeMore",0,{display:"none"})
        
        })
    })
    p.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{

      tl.to(".app-cursor",0.2,{width:"30px",height:"30px"})
      tl.to(".follower",0,{display:"none"})

      
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
      tl.to(".follower",0,{display:"block"})
        
        })
    })
    hOne.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{

      tl.to(".app-cursor",0.2,{width:"60px",height:"60px"})
      tl.to(".follower",0,{display:"none"})

      
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
      tl.to(".follower",0,{display:"block"})
        
        })
    })
    hTwo.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{

      tl.to(".app-cursor",0.2,{width:"50px",height:"50px"})
      tl.to(".follower",0,{display:"none"})

      
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
      tl.to(".follower",0,{display:"block"})
        
        })
    })
    panel.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{

      tl.to(".app-cursor",0.2,{width:"70px",height:"70px"})
      tl.to(".follower",0,{display:"none"})

      
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
      tl.to(".follower",0,{display:"block"})
        
        })
    })
    item.forEach((el) =>{
      el.addEventListener("mouseover",(elm) =>{

      tl.to(".app-cursor",0.2,{width:"30px",height:"30px"})
      tl.to(".follower",0,{display:"none"})

      
      })
      el.addEventListener("mouseleave",(elm) =>{

        tl.to(".app-cursor",0.2,{width:"9px",height:"9px"})
      tl.to(".follower",0,{display:"block"})
        
        })
    })
},[])

  return (<>
    <div className="app-cursor" ref={customRef}>
      <p className="seeMore">See More</p>
    </div>
    <div className="follower"></div>
  </>

  )
}

export default CustomCursor
