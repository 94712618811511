import React,{useEffect,useState,Suspense,lazy} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './background.css';

import CustomCursor from "./components/elements/cursor/cursor"
import loader from "./assets/loader.gif"

const Main = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("../src/components/main")), 3000);
  });
});
const AdminLoginPage = lazy(() =>import("./adminPanel/adminPage/adminLoginPage"))
const AdminDashboard = lazy(() =>import("./adminPanel/adminDashboard/dashboard"))

const Welcome = lazy(() =>import("./adminPanel/adminDashboard/pages/welcome"))
const ClientReviews = lazy(() =>import("./adminPanel/adminDashboard/pages/ClientReviews"))
const MembersInfo = lazy(() =>import("./adminPanel/adminDashboard/pages/MembersInfo"))
const ServiceInfo = lazy(() =>import("./adminPanel/adminDashboard/pages/Service"))
const PortfolioImages = lazy(() =>import("./adminPanel/adminDashboard/pages/PortfolioImages"))


function App() {
useEffect(()=>{
  if(!window.console){
    const noConsole = function(){}
    console = {
      log:noConsole,
      warn:noConsole,
      error:noConsole
    }
  }

},[])
  return (
    <div >
    <BrowserRouter>
          <Suspense fallback={ 
            <div className='app'>
           <img src={loader} alt="Codevaly Loader"/>
           </div>
          }>
            <CustomCursor/>
       <Routes>
        <Route path="/" exect element={<Main/>}/>
        <Route path="/cv-admin" element={<AdminLoginPage/>}/>
        <Route path="dashboard" element={<AdminDashboard/>}>
          <Route path="" element={<Welcome/>}/>
          <Route path="reviews" element={<ClientReviews/>}/>
          <Route path="members" element={<MembersInfo/>}/>
          <Route path="portfolio" element={<PortfolioImages/>}/>
          <Route path="service" element={<ServiceInfo/>}/>
        </Route>
      </Routes>
          </Suspense>
    </BrowserRouter>

     
    {/* {
      loading ?
          <div className='app'>
          <ProgressBar
            borderColor = '#5b6277'
            barColor = '#00B5C0'
          />
          </div>
          :
          <BrowserRouter>
          <CustomCursor/>
       <Routes>
        <Route path="/" exect element={<Main/>}/>
        <Route path="/cv-admin" element={<AdminLoginPage/>}/>
        <Route path="dashboard" element={<AdminDashboard/>}>
          <Route path="" element={<Welcome/>}/>
          <Route path="reviews" element={<ClientReviews/>}/>
          <Route path="members" element={<MembersInfo/>}/>
          <Route path="portfolio" element={<PortfolioImages/>}/>
          <Route path="service" element={<ServiceInfo/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    } */}
    

<div className='bg'>
 <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
</div>

</div>
  );
}

export default App;
